export const testimonials = [
    {
        content: `“I couldn't be happier with this SAT prep website! The personalized tests created using AI really target my weak areas. The flexible testing times mean I can practice without committing to a full-length exam.”`,
        writer: "Sarah M."
    },
    {
        content: `“This site has been a game-changer for my SAT preparation. The personalized tests ensure that I'm always challenged and improving. The ability to take tests at my convenience is fantastic—I can study around my busy schedule.”`,
        writer: "Alex P."
    },
    {
        content: `“Preparing for the SAT has never been easier thanks to this amazing website. The personalized tests are spot-on in addressing my needs, and I love the flexibility of not having to take a full mock exam all at once.”`,
        writer: "Emily R."
    },
]

export const durations = [
    {
      id: "30m",
      label: "30 minutes",
      value: 1800
    },
    {
        id: "45m",
        label: "45 minutes",
        value: 2700
      }
    // {
    //   id: "60m",
    //   label: "1 hour",
    //   value: 3600
    // },{
    //   id: "120m",
    //   label: "2 hours",
    //   value: 7200
    // }
  ]