import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'

import Header from './components/Header';
import Footer from './components/Footer';


const Dashboard = () => {
  const [modalShow, setModalShow] = useState(false);
  const [questionModalShow, setQuestionModalShow] = useState(false);

  const testResults = [
    {
      id: 1,
      topic: "Words in context",
      myAnswer: "N-A",
      rightAnswer: 3,
      result: "...",
      time: 37,
      difficulty: "Medium",
    },
    // Add more test results as needed...
  ];

  const testDetails = [
    {
      id: 1,
      testName: "Digital SAT M...",
      date: "Apr 22 , 2024",
      question: 98,
      attempt: 58,
      correct: 25,
      testDuration: "45 Mins",
      timeTaken: "38 Mins",
    },
    // Add more test details as needed...
  ];

  const subjects = [
    "Maths", "Technology", "Biology", "Computer Science", "Law", "Physical Education"
  ];

  const durations = ["30 minutes", "1 hour", "2 hours"];

  return (
    <div>
      <Header />

      <section className="user-board-panel">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-xl-5 col-lg-5 col-md-12 col-12">
              <div className="row mb-5">
                <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                  <select className="form-select" aria-label="Default select example">
                    <option defaultValue>select Subject</option>
                    {subjects.map((subject, index) => (
                      <option key={index} value={index + 1}>{subject}</option>
                    ))}
                  </select>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                  <select className="form-select" aria-label="Default select example">
                    <option defaultValue>Duration</option>
                    {durations.map((duration, index) => (
                      <option key={index} value={index + 1}>{duration}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="actionbox d-grid gap-2 col-6 mx-auto">
                <button className="btn btn-danger btn-lg">Take Personalized Tests</button>
                <div className="test-info-box mt-3 mb-3">
                  <p className="mb-0 text-center">
                    There is a limit to how many tests <br />
                    can be taken within one day
                  </p>
                </div>
                <button className="btn btn-danger btn-lg btn-d" disabled>Take Full Length Tests</button>
              </div>
            </div>
            <div className="col-xl-5 col-lg-5 col-md-12 col-12">
            
      {/* Other content of the component */}
      
      <div className="row">
        <div className="col-xl-6 col-lg-6 col-md-12 col-12">
          <div className="board-info-box fastest" onClick={() => setModalShow(true)}>
            <div className="board-icon-box">
              <i className="icon-Fastest"></i>
            </div>
            <div className="board-label-box">Your Fastest Area</div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-12 col-12">
          <div className="board-info-box slowest" onClick={() => setModalShow(true)}>
            <div className="board-icon-box">
              <i className="icon-Slowest"></i>
            </div>
            <div className="board-label-box">Your Slowest Area</div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-12 col-12">
          <div className="board-info-box weak" onClick={() => setModalShow(true)}>
            <div className="board-icon-box">
              <i className="icon-Weak"></i>
            </div>
            <div className="board-label-box">Your Weak Area</div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-12 col-12">
          <div className="board-info-box best" onClick={() => setModalShow(true)}>
            <div className="board-icon-box">
              <i className="icon-Best"></i>
            </div>
            <div className="board-label-box">Your Best Area</div>
          </div>
        </div>
      </div>


            </div>
          </div>
          <div className="row mt-5">
            <div className="col-xl-12 col-lg-12 col-md-12 col-12 userDataTable">
              <table id="userDataTable" className="table hover cell-border" style={{ width: '100%' }}>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Test Name</th>
                    <th>Date</th>
                    <th>Question</th>
                    <th>Attempt</th>
                    <th>Correct</th>
                    <th>Test Duration</th>
                    <th>Time Taken</th>
                    <th>View</th>
                  </tr>
                </thead>
                <tbody>
                  {testDetails.map((detail, index) => (
                    <tr key={index}>
                      <td>{detail.id}</td>
                      <td>{detail.testName}</td>
                      <td>{detail.date}</td>
                      <td>{detail.question}</td>
                      <td>{detail.attempt}</td>
                      <td>{detail.correct}</td>
                      <td>{detail.testDuration}</td>
                      <td>{detail.timeTaken}</td>
                      <td><button className="btn btn-outline-secondary" onClick={() => setModalShow(true)}>Analysis</button></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>

      {/* Modal for Analysis */}
      {modalShow && (
        <div className="modal fade show" style={{ display: 'block' }} id="analysis" tabIndex="-1" aria-labelledby="analysis" aria-hidden="true">
          <div className="modal-dialog modal-fullscreen">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  <div className="d-inline p-2 bg-primary text-white mx-3">Test</div> Digital SAT M...
                </h5>
                <button type="button" className="btn-close" onClick={() => setModalShow(false)} aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <table id="userDataTable" className="table hover cell-border" style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Topic</th>
                      <th>My Answer</th>
                      <th>Right Answer</th>
                      <th>Result</th>
                      <th>Time(Sec)</th>
                      <th>Difficulty</th>
                      <th>Explanation</th>
                    </tr>
                  </thead>
                  <tbody>
                    {testResults.map((result, index) => (
                      <tr key={index}>
                        <td>{result.id}</td>
                        <td>{result.topic}</td>
                        <td>{result.myAnswer}</td>
                        <td>{result.rightAnswer}</td>
                        <td>{result.result}</td>
                        <td>{result.time}</td>
                        <td>{result.difficulty}</td>
                        <td>
                          <button className="btn btn-danger" onClick={() => setQuestionModalShow(true)}>View</button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-danger" onClick={() => setModalShow(false)}>Close</button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal for Question Review */}
      {questionModalShow && (
        <div className="modal fade show" style={{ display: 'block' }} id="viewQuestion" tabIndex="-1" aria-labelledby="viewQuestion" aria-hidden="true">
          <div className="modal-dialog modal-dialog-scrollable modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="viewQuestion">Question Review</h5>
                <button type="button" className="btn-close" onClick={() => setQuestionModalShow(false)} aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-xl-2 col-lg-2 col-md-2 col-12">
                    <div className="question-detail-number-box">Question: 1</div>
                  </div>
                  <div className="col-xl-10 col-lg-10 col-md-10 col-12">
                    <div className="question-detail-text-box">
                      <div className="question-detail-text-short-info">Which of the following values is NOT equal to 34(58+9)?</div>
                      <div className="question-detail-text-full-info">
                        An instrument store gives a 10% discount to all students off the original cost of an instrument. During a back to school sale an additional 15% is taken off the discounted price. Julie, a student at the local high school, purchases a flute for $306. How much did it originally cost?
                      </div>
                      <div className="question-detail-list">
                        <ul>
                          <li><span>1.</span> Success</li>
                          <li><span>2.</span> Thought</li>
                          <li><span>3.</span> Divide</li>
                          <li><span>4.</span> Ambition</li>
                        </ul>
                      </div>
                      <div className="correct-answer-box">
                        <div className="correct-answer-line">Correct Answer: (3)</div>
                        <div className="correct-answer-line your-answer">Your Answer: (3)</div>
                      </div>
                      <div className="user-answer-detail">
                        The cost to ride on a ferry is $5.00 per vehicle and driver with an additional cost of 50 cents per passenger. If the charge to get on the ferry is $6.50, how many people were in the vehicle?
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-danger" onClick={() => setQuestionModalShow(false)}>Close</button>
              </div>
            </div>
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
};


export default Dashboard;

