import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import backgroundImg from './assets/news-page.jpeg';
import Footer from './components/Footer';
import Header from './components/Header';
import { getBlogList } from './components/apiCalls'; // Ensure this function is correctly defined

function Blogs() {
  const [blogsList, setBlogsList] = useState([]);

  useEffect(() => {
    getBlogLists();
  }, []);

  const getBlogLists = async () => {
    try {
      let data = await getBlogList();
      if (data?.blogList) {
        setBlogsList(data.blogList);
      } else {
        console.error("API response format is incorrect or missing 'blogList'");
      }
    } catch (error) {
      console.error("An error occurred while fetching blog data:", error);
    }
  };

  const sanitizeAndResizeImages = (htmlContent) => {
    const div = document.createElement('div');
    div.innerHTML = htmlContent;

    const images = div.querySelectorAll('img');
    images.forEach((img) => {
      img.style.width = '150px';
      img.style.height = '150px';
      img.style.objectFit = 'cover';
    });

    return div.innerHTML;
  };

  return (
    <>
      <div>
        <Header route='blogs' />

        <section className="inner-header">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-12">
                <h1 className="inner-header--title">
                  Eduprepme <span>Blogs</span>
                </h1>
                <div className="inner-header--detail">
                  <p>
                    Stay updated with the latest blog articles from various categories.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="inner-header--bg">
            <img src={backgroundImg} alt="Background" />
          </div>
        </section>

        <section className="blue-section blog-section">
          <div className="container">
            <div className="row">
              {blogsList.length > 0 ? (
                blogsList.map((blogItem, index) => (
                  <div key={index} className="col-md-6 col-lg-4 mb-4">
                    <div className="card h-100 shadow-sm">
                      <img
                        src={blogItem.image || 'default-image-path.jpg'} // Replace with a default image path if none is provided
                        alt="Blog"
                        style={{
                          width: '200px',
                          height: '200px',
                          objectFit: 'cover', // Ensures the image covers the area without stretching
                          margin: '0 auto', // Center the image horizontally
                          display: 'block' // Ensure the image is block level
                        }}
                      />
                      <div className="card-body d-flex flex-column">
                        <h5 className="card-title">
                          <strong>{blogItem.title}</strong>
                        </h5>
                        <p className="card-text text-muted" 
                           dangerouslySetInnerHTML={{ 
                             __html: sanitizeAndResizeImages(blogItem.description) 
                           }} 
                        />
                        <a href="#!" className="btn btn-primary mt-auto">
                          Read more
                        </a>
                      </div>
                      <div className="card-footer text-muted">
                        Created on: {new Date(blogItem.createdOn).toLocaleDateString()}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>No blogs available at the moment.</p>
              )}
            </div>
          </div>

          <nav className="my-4" aria-label="...">
            <ul className="pagination pagination-circle justify-content-center">
              <li className="page-item">
                <a className="page-link" href="#" tabIndex="-1" aria-disabled="true">Previous</a>
              </li>
              <li className="page-item"><a className="page-link" href="#">1</a></li>
              <li className="page-item active" aria-current="page">
                <a className="page-link" href="#">2</a></li>
              <li className="page-item"><a className="page-link" href="#">3</a></li>
              <li className="page-item">
                <a className="page-link" href="#">Next</a>
              </li>
            </ul>
          </nav>
        </section>

        <Footer />
      </div>
    </>
  );
}

export default Blogs;
