import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Teams.css';
import backgroundImg from './assets/background-img-view.jpeg';
import Team from './assets/team.jpeg';
import Header from './components/Header';
import Footer from './components/Footer';
import { getDashboard } from './components/apiCalls';

function Teams() {
  const [teamList, setTeamList] = useState([]);

  useEffect(() => {
    getTeamList();
  }, []);

  const getTeamList = async () => {
    try {
      let data = await getDashboard();
      if (data && data.teamList) {
        setTeamList(data.teamList);
      } else {
        console.error("API response format is incorrect or missing 'teamList'");
      }
    } catch (error) {
      console.error("An error occurred while fetching Team data:", error);
    }
  };

  return (
    <>
      <div className='teams-container'>
        <Header route='teams' />
        <section className="inner-header">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-12">
                <h1 className="inner-header--title">
                  Meet Our&nbsp;
                  <span>Team</span>
                </h1>
                <div className="inner-header--detail">
                  {/* <p>
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit. Temporibus, expedita iusto veniam atque,
                  </p> */}
                </div>
              </div>
            </div>
          </div>
          <div className="inner-header--bg">
            <img src={backgroundImg} alt="" />
          </div>
        </section>
        <section className="blue-section">
          <div className="container">
            <div className="row prime-container">
              {teamList.slice(0, 2).map((teamMember, index) => (
                <div className={`team-tile-container col-lg-6 col-md-12 col-sm-12 mb-4`} key={teamMember.id}>
                  <div className="card border-0 shadow">
                    {teamMember.image ? <div className="team-img" data-mdb-ripple-color="light">
                      <img src={`./img/teams/${teamMember.image}`} className="w-100" alt={teamMember.name} />
                    </div>  : null}
                    <div className="card-body text-center">
                      <h5 className="card-title mb-0">{teamMember.name}</h5>
                      <div className="card-text designation">{teamMember.designation}</div>
                      <div className="card-text text-black-50">{teamMember.description}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="row non-prime-container">
              {teamList.slice(2, teamList.length).map((teamMember, index) => (
                <div className={`team-tile-container col-lg-4 col-md-12 mb-4`} key={teamMember.id}>
                  <div className="card border-0 shadow">
                    {teamMember.image ? <div className="team-img" data-mdb-ripple-color="light">
                      <img src={`./img/teams/${teamMember.image}`} className="w-100" alt={teamMember.name} />
                    </div>  : null}
                    <div className="card-body text-center">
                      <h5 className="card-title mb-0">{teamMember.name}</h5>
                      <div className="card-text designation">{teamMember.designation}</div>
                      <div className="card-text text-black-50">{teamMember.description}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* <div className="row">
            <div className="team-content-temp">A passionate team with a solid background in computer science and algorithms is dedicated to tackling the Digital SAT challenge. They focus on creating personalized tests through AI-generated questions to enhance the testing experience and better meet students' needs.</div>
            </div> */}

        </section>
        <Footer />
      </div>
    </>
  );
}

export default Teams;
