import React, { useState, useEffect, useRef } from 'react';

const getRemainingTime = (durationInSeconds) => {
  return {
    remainingSeconds: durationInSeconds,
    minutes: Math.floor(durationInSeconds / 60),
    seconds: durationInSeconds % 60
  }
}

const CountdownTimer = ({ duration }) => {
  const timeRemaining = getRemainingTime(duration); // Initialize with total seconds

  return (
    <div className="timebox text-center">
      {timeRemaining.remainingSeconds > 0 ? `${timeRemaining.minutes} Minutes ${timeRemaining.seconds < 10 ? '0' : ''}${timeRemaining.seconds} Seconds` : 'Time is up!'}
    </div>
  );
};
export default CountdownTimer;
