// src/components/DesmosCalculator.js
import React, { useEffect, useRef } from 'react';
import Desmos from 'desmos';

const DesmosCalculator = ({ onClose }) => {
  const calculatorRef = useRef(null);

  useEffect(() => {
    const elt = calculatorRef.current;
    const calculator = Desmos.GraphingCalculator(elt, {
      keypad: true,
      expressions: true,
    });

    // Clean up the calculator instance on unmount
    return () => calculator.destroy();
  }, []);

  return (
    <div className="modal show" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Desmos Calculator</h5>
            <button type="button" className="btn-close" onClick={onClose}></button>
          </div>
          <div className="modal-body">
            <div ref={calculatorRef} style={{ width: '100%', height: '400px' }} />
          </div>
          <div className="modal-footer">
            <button onClick={onClose} className="btn btn-danger">Close</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesmosCalculator;
