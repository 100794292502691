const AnalysisModal = ({ open, onClose, data }) => {
    return (<div
        className={`modal fade ${open ? 'show' : ''}`}
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden={!open}
        style={{ display: open ? 'block' : 'none' }}
    >
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">{ }</h5>
                    <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={onClose}
                    ></button>
                </div>
                <div className="modal-body">
                    <div className="all-questions-status-row">
                        {data.analysisHeading}
                    </div>
                    <div class="d-flex flex-column">
                        {data.analysisAreas.map((item) => (<li key={item} class="d-flex align-items-center py-2">
                            <span class="bullet bullet-dot bg-primary me-3"></span> {item}
                        </li>))}

                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-danger"
                        data-bs-dismiss="modal"
                        onClick={onClose}
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    </div>)
}

AnalysisModal.defaultProps = {
    open: false,
    onClose: () => { },
    data: {
        analysisHeading: '',
        analysisAreas: [],
    }
}

export default AnalysisModal