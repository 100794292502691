import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'
import backgroundImg  from './assets/background-img-view.jpeg'
import onlinetest  from './assets/online-test.jpeg'
import logo  from './assets/logo.png'
import { Link, useNavigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';


function OnlineTest() {

  const [user,setUser]=useState(JSON.parse(localStorage.getItem('student-token')))
  const navigate = useNavigate();

  useEffect(() => {

    if(!user){
      navigate('/')
    }
  
   
  }, []);

  return (
  <>
<div>
<Header route="online-test" />

  <section class="inner-header">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-12">

          <h1 class="inner-header--title">
            Brilliant Analytics <span>Online Test</span>
          </h1>
          <div class="inner-header--detail">
            <p>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit.
              Temporibus, expedita iusto veniam atque,
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="inner-header--bg">
      <img src={backgroundImg} alt=""/>
    </div>
  </section>

  <section class="copy-and-image--box">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-12 col-lg-6">
          <h1 class="copy-and-image--title">
            The best offer <br />
            <span>for your business</span>
          </h1>
          <div class="copy-and-image--detail">
            <p>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit.
              Temporibus, expedita iusto veniam atque, magni tempora mollitia
              dolorum consequatur nulla, neque debitis eos reprehenderit quasi
              ab ipsum nisi dolorem modi. Quos?
            </p>
          </div>
        </div>

        <div class="col-12 col-lg-6">
          <img src={onlinetest} class="img-fluid" alt="" />

        </div>
      </div>
    </div>
  </section>

  <section class="blue-section">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-12 col-lg-12 col-xl-12 text-center">
          <h2 class="blue-section--heading"> Don't Have Time for a Practice Test?</h2>
          <div class="blue-section--info-text">
            <p>Check out these other free options.

            </p>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6 col-xl-6">
          <div class="card text-black">

            <div class="card-body">
              <div class="text-center">
                <h5 class="card-title">Have 20 Minutes?
                </h5>
                <p class="text-muted mb-4">Test your GRE skills with 12 exam-style questions in Kaplan's 20-Minute
                  Workout. You'll get explanations for every question—plus tips that will help raise your score.

                </p>
             
                <Link to="/questions">
                <a  class="btn btn-danger">Get Started</a>
          </Link>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6 col-xl-6">
          <div class="card text-black">

            <div class="card-body">
              <div class="text-center">
                <h5 class="card-title">Got a Minute?
                </h5>
                <p class="text-muted mb-4">A little routine goes a long way. With Kaplan's Question of the Day, you'll
                  tackle new exam-style questions every day—starting right now.

                </p>
                <a href="" class="btn btn-danger">Answer Today's Question</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  
 <Footer/>

</div>
  </>
  );
}

export default OnlineTest;

