// src/Questions.js
import React, { useContext, useEffect, useRef, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Toast } from 'bootstrap';
import { TestContext } from './TextContext';
import { getTestQuestions, saveTest } from './components/apiCalls';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import logo from './assets/logo.png';
import CountdownTimer from './components/countDown';
import DesmosCalculator from './components/DesmosCalculator';
import { actionNotifier } from './components/actionNotice';
import queryString from 'query-string'
import Loader from './components/Loader';

const TimerWorker = new Worker('timeWorker.js', { type: 'module' })

function Questions() {
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const currentQuestionIndexRef = useRef(currentQuestionIndex)
  const [currentQuestion ,setCurrentQuestion] = useState({});
  const [questionsINitialized ,setQuestionsINitialized] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [durationInSeconds, setDurationInSeconds] = useState(null);
  const [user] = useState(JSON.parse(localStorage.getItem('student-token')))
  const navigate = useNavigate()
  const [timeEnded, setTimeEnded] = useState(false);
  const [id, setId] = useState('');
  const [title, setTitle] = useState('');

  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  const [showCalculatorModal, setShowCalculatorModal] = useState(false);
  useEffect(() => {

    if (!user) {
      navigate('/')
    } else {
      getAllQuestions();
    }


  }, [location]);

  const getAllQuestions = async () => {
    try {
      const { id, duration, noOfQuestion, title, type, userId } = queryString.parse(window.location.search);

      if (!id || !duration || !noOfQuestion || !title || !type || !userId) {

        setIsLoading(false); // Hide loader in case of no test data
        return;
      }

      let val = {
        duration: duration.toString(),
        id: id,
        noOfQuestion: noOfQuestion.toString(),
        title: title.toString(),
        type: type.toString(),
        userId: parseInt(userId),
      };
      setId(id);
      setTitle(title);
      const data = await getTestQuestions(val);
      if (data && data.length > 0) {
        const allQuestions = data.flatMap(item => item.questionList);
        setQuestions(allQuestions);
        setCurrentQuestion(allQuestions[0])
      }
      if (duration) {
        setDurationInSeconds(duration)
      }
      setIsLoading(false); // Hide loader after data is fetched
    } catch (error) {
      console.error('Error fetching questions:', error);
      setIsLoading(false); // Hide loader on error
    }
  };



  const submitAnswer = async () => {
    setConfirmation(true); // Show confirmation modal
  };

  const handleConfirmation = async (confirmed) => {
    if (confirmed) {
      try {
        setIsLoading(true);
        const answersToSubmit = questions.map((q, index) => ({
          id: 0,
          testId: id ? parseInt(id) : id,
          questionId: questions[index]?.id,
          studentId: user?.userId,
          selectedOptionId: q.selectedOptionId,
          timeTaken: q.timeTaken,
          markReview: questions[index]?.markReview ? 1 : 0,
        }))
        // .filter(q => q.selectedOptionId !== undefined && q.selectedOptionId !== null);
        
        const data = await saveTest(answersToSubmit);
        if (data === true) {
          setIsLoading(false);
          actionNotifier.success('Test Submitted Successfully');
          window.close()
        } else {
          // Handle submission failure
        }
      } catch (error) {
        console.error('Error saving answers:', error);
      }
    }
    setShowModal(false); // Close modal regardless of submission status
  };


  const setQuestion = (index) => {
    setCurrentQuestionIndex(index)
    currentQuestionIndexRef.current = index
    setCurrentQuestion(questions[index])
  }


  const handleNextClick = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setQuestion(currentQuestionIndex + 1)
    }
  };


  const handlePrevClick = () => {
    if (currentQuestionIndex > 0) {
      setQuestion(currentQuestionIndex - 1);
    }
  };


  const isSelected = (questionIndex, optionId) => {
    return questions[questionIndex].selectedOptionId === optionId;
  };


  const handleOptionChange = (questionIndex, optionId) => {
    setQuestions(questions.map((q, qIndex) => {
      if (qIndex === questionIndex) {
        q.selectedOptionId = q.selectedOptionId !== optionId ? optionId : null
      }
      return q
    }))
    handleCrossClick(questionIndex, optionId, false)
  };

  const handleReviewClick = () => {
    setShowModal(true);
    const dropdown = document.getElementById('toastbtn2');
    dropdown.click();
  };

  const handledropdown = () => {
    const myAlert = document.getElementById("liveToast");
    const bsAlert = new Toast(myAlert);
    bsAlert.show();
  };

  const handleMarkForReview = (index) => {
    // Toggle the review status for the current question
    setQuestions(questions.map((q, qIndex) => {
      if (qIndex === index) {
        q.markReview = !q.markReview
      }
      return q
    }))
  };

  const handleCrossClick = (questionIndex, optionId, decorated) => {
    setQuestions(questions.map((q, qIndex) => {
      if (qIndex === questionIndex) {
        q.optionList = q.optionList.map((o) => {
          if (o.id === optionId) {
            o.decorated = decorated
          }
          return o
        })
      }
      return q
    }))
  };

  useEffect(() => {
    if (questions.length > 0 && !questionsINitialized) {
      setQuestionsINitialized(true)
    }
  }, [questions, questionsINitialized])

  useEffect(() => {
    if (!questionsINitialized || timeEnded) {
      return
    }
    TimerWorker.postMessage({ action: 'start', duration: 1000 });
  }, [timeEnded, questionsINitialized])
  
  useEffect(() => {
    // Listen for messages from the worker
    TimerWorker.onmessage = (e) => {
      if (e.data === 'tick') {
        setQuestions((questions) => {
          return questions.map((q, qIndex) => {
            if (qIndex === currentQuestionIndexRef.current) {
              q.timeTaken = (q.timeTaken || 0) + 1
            }
            return q
          })
        })
        setDurationInSeconds((duration) => {
          return duration - 1
        })
      }
    };
    // Clean up the worker on component unmount
    return () => {
      TimerWorker.terminate();
    };
  }, []);

  useEffect(() => {
    if (durationInSeconds === 0) {
        TimerWorker.postMessage({ action: 'stop' });
        setTimeEnded(true)
    }
  }, [durationInSeconds])

  if (isLoading) {
    return <Loader />; // Show loader while fetching data
  }

  return (
    <>
      <div>
        <section className="questions-all-section">
          <header className="questions-header">
            <div className="container">

              <div className="row">
                <div className="col-xl-2 col-lg-2 col-md-2 col-12">
                  <div className="btn btn-danger subject-name">{title}</div>
                  {/* <div className="directions-panel">
                    <div className="btn btn-outline-danger directions-box custom-icons" id="toastbtn" onClick={handledropdown}>
                      Directions <i className="triangle_down"></i>
                    </div>
                    <div id="liveToast" className="toast hide" role="alert" aria-live="assertive" aria-atomic="true" data-bs-autohide="false">
                      <div className="toast-header">
                        <strong className="me-auto">Directions text will be shown here</strong>
                        <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                      </div>
                      <div className="toast-body">
                        <div dangerouslySetInnerHTML={{ __html: currentQuestion.hint }}></div>
                      </div>
                    </div>
                  </div> */}
                </div>

                <div className="col-xl-2 col-lg-2 col-md-2 col-12">
                  <div className="timebox-panel show" id="collapseExample">
                    <div className="time-label text-center">Remaining Time</div><CountdownTimer  duration={durationInSeconds} />
                  </div>
                  <div className="d-grid gap-2">
                    <button
                      className="timeShowHide btn btn-light"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseExample"
                      aria-expanded="true"
                      aria-controls="collapseExample"
                    ></button>
                  </div>
                </div>
                <div className="col-xl-5 col-lg-5 col-md-5 col-12">
                  <div className="userNameBox text-center">
                    <span>Hi {user?.name}</span>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                  <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                    <div onClick={() => setShowCalculatorModal(true)} className="btn btn-danger calculator">Desmos Calculator</div>
                    <button className="btn btn-success calculator me-auto" style={{ backgroundColor: '#0d6efd' }} onClick={submitAnswer}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </header>
          {showCalculatorModal && (
            <DesmosCalculator onClose={() => setShowCalculatorModal(false)} />
          )}
          <div class="questions-body">
            <div class="container">
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-12">
                  <div className="questions-heading-box">
                    <div>
                      <span>{currentQuestionIndex + 1}.</span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: currentQuestion?.description
                            ? `<strong></strong> ${currentQuestion?.description.replace(
                              /<img/g,
                              '<img style="height: 150px; width: 100%;"'
                            )}`
                            : "",
                        }}

                      ></span>
                    </div>
                    {/* <div dangerouslySetInnerHTML={{ __html: currentQuestion.hint }}></div> */}
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-12">
                  <div class="questions-holder-box">
                    <div class="questions-title">
                      <ul class="questions-marker">
                        <li>Question # <span>{currentQuestionIndex + 1}</span></li>
                        <li> Mark for Review</li>
                        <li class="markeicon" onClick={() => {
                          handleMarkForReview(currentQuestionIndex)
                        }}>
                          <span id="reviewMarker" className={` ${currentQuestion.markReview ? 'markForReview' : ''}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="161" height="224" viewBox="0 0 161 224" fill="none">
                              <path d="M11.7797 171.221L75.7797 213.821C77.0762 214.706 78.6097 215.18 80.1797 215.18C81.7498 215.18 83.2833 214.706 84.5797 213.821L148.58 171.221C149.695 170.497 150.61 169.504 151.239 168.332C151.868 167.161 152.192 165.85 152.18 164.521L152.18 8.82059L84.5797 53.8206C83.2833 54.7063 81.7498 55.1801 80.1797 55.1801C78.6097 55.1801 77.0762 54.7063 75.7798 53.8206L8.17975 8.82058L8.17974 164.521C8.16753 165.85 8.49106 167.161 9.12035 168.332C9.74965 169.504 10.6644 170.497 11.7797 171.221Z" stroke="black" stroke-width="16" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="questions-item">
                      {currentQuestion.optionList && currentQuestion.optionList.map(option => (
                        <div className="checkQuestion" key={option.id}>
                          <input
                            className="styled-checkbox"
                            id={`styled-checkbox-${option.id}`}
                            type="checkbox"
                            checked={isSelected(currentQuestionIndex, option.id)}
                            onChange={() => {
                              handleOptionChange(currentQuestionIndex, option.id)
                            }}
                          />
                          <label
                            htmlFor={`styled-checkbox-${option.id}`}
                            style={{
                              textDecoration: option?.decorated ? 'line-through' : 'none'
                            }}
                            className='checkQuestion-option'
                            dangerouslySetInnerHTML={{
                              __html: option?.options
                                ? `<strong></strong> ${option.options.replace(
                                  /<img/g,
                                  '<img style="height: 150px; width: 100%;"'
                                )}`
                                : "",
                            }}
                          />

                          {/* Cross icon or symbol */}
                          {!option?.decorated ? (
                            <span className="cross-icon" onClick={() => handleCrossClick(currentQuestionIndex, option.id, true)}>
                              Cross
                            </span>
                          ): (
                            <span className="redo-text" onClick={() => handleCrossClick(currentQuestionIndex, option.id, false)}>
                              Redo
                            </span>
                          )}
                        </div>
                      ))}



                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>

          <footer className="questions-footer">
            <div className="container">
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                  <div className="questions-footerlogo">
                    <Link to="/">
                      <img src={logo} alt="" />
                    </Link>
                  </div>
                  <div className="questions-footer-info">
                    <p>SAT® is a registered trademark of the College Board, which was not involved in the production of, and does not endorse this product.</p>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-12 bottom-reivew-panel">
                  <div className="bottom-reivew-box mx-auto">
                    <button
                      className="btn btn-outline-danger directions-box custom-icons"
                      id="toastbtn2"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      onClick={handleReviewClick}
                    >
                      Review <i className="triangle_down"></i>
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="toastbtn2">
                      <li className="dropdown-header">Directions text will be shown here</li>
                      <li>
                        <a className="dropdown-item" href="#!">Direction 1</a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#!">Direction 2</a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#!">Direction 3</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                  <div className="d-flex justify-content-end align-items-center">
                    <button className="btn btn-danger calculator" onClick={handlePrevClick} disabled={currentQuestionIndex === 0 || timeEnded}>
                      Previous
                    </button>
                    <button className="btn btn-danger calculator ms-2" onClick={handleNextClick} disabled={currentQuestionIndex >= questions.length - 1 ||  timeEnded}>
                      Next
                    </button>
                  </div>
                </div>

              </div>
            </div>
          </footer>
        </section>

        {/* Display calculator */}
        <div
          className={`modal fade ${showModal ? 'show' : ''}`}
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden={!showModal}
          style={{ display: showModal ? 'block' : 'none' }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Directions text will be show here</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setShowModal(false)}
                ></button>
              </div>
              <div className="modal-body">
                <div className="statusrow">
                  <div className="current-icon"><i className="icon-location-pointer"></i><span>Current</span></div>
                  <div className="unanswered-icon"><i></i><span>unanswered</span></div>
                  <div className="review-icon"><i className="icon-save_mark"></i><span>For Review</span></div>
                </div>
                <div className="all-questions-status">
                  <div className="all-questions-status-row">
                    {questions.map((item, index) => (
                      <div
                        key={index}
                        className={`all-questions-number${item.markReview ? ' review-question' : ''}${!item.selectedOptionId ? ' unanswered' : ''}`}
                        onClick={() => {
                          setQuestion(index);
                          setShowModal(false);
                        }}
                      >
                        {item.markReview && <i className="icon-save_mark"></i>} {index + 1}
                      </div>
                    ))}

                  </div>

                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger"
                  data-bs-dismiss="modal"
                  onClick={() => setShowModal(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className={`modal fade ${confirmation ? 'show' : ''}`} id="submitModal" tabIndex="-1" aria-labelledby="submitModalLabel" aria-hidden={!confirmation} style={{ display: confirmation ? 'block' : 'none' }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
              <button type="button" style={{ position: 'absolute', top: '15px', right: '15px' }} className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setConfirmation(false)}></button>
                <h5 className="modal-title" id="submitModalLabel">Are you sure you want to submit the practice test (you won’t be able to change your answers afterwards)?</h5>
                
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => setConfirmation(false)}>No</button>
                <button type="button" className="btn btn-primary" onClick={() => handleConfirmation(true)}>Yes</button>
              </div>
            </div>
          </div>
        </div>




      </div>
    </>
  );
}

export default Questions;
