import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import backgroundImg from './assets/background-img-view.jpeg';
import forgot from './assets/forgot-password.jpeg';
import logo from './assets/logo.png';
import { Link, useNavigate } from 'react-router-dom';
import { forgot_password, new_password } from './components/apiCalls';
import { actionNotifier } from './components/actionNotice';

function ForgotPassword() {
  const [isEmailSubmitted, setIsEmailSubmitted] = useState(false);
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const navigate = useNavigate();

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = await forgot_password({ email });
      if (data == true) {
        actionNotifier.success('Email verified. Please enter the OTP sent to your email and your new password.');
        setIsEmailSubmitted(true);
      } else {
        actionNotifier.error('Failed to verify email.');
      }
    } catch (error) {
      actionNotifier.error('An error occurred while verifying email.');
      console.error(error);
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = await new_password({ email, otp, newPassword });
      if (data == true) {
        actionNotifier.success('New password created successfully.');
        navigate('/login')
        // Optionally redirect the user or reset the form
      } else {
        actionNotifier.error('Failed to reset password.');
      }
    } catch (error) {
      actionNotifier.error('An error occurred while resetting the password.');
      console.error(error);
    }
  };

  return (
    <>
      <div>
        <header className="main-header">
          <section className="topBar">
            <div className="container topBar-container">
              <ul className="social-media-box">
                <li><a href="#"><i className="icon-instagram"></i></a></li>
                <li><a href="#"><i className="icon-linkedin"></i></a></li>
                <li><a href="#"><i className="icon-facebook"></i></a></li>
                <li><a href="#"><i className="icon-youtube"></i></a></li>
              </ul>
              <div className="user-panel-box">
                <Link to="/login" className="btn btn-danger">SIGN IN</Link>
                <Link to="/signup" className="btn btn-danger">JOIN NOW</Link>
              </div>
            </div>
          </section>

          <section className="main-menu">
            <nav className="navbar navbar-expand-lg bg-body-tertiary">
              <div className="container">
                <Link to="/"><img src={logo} alt="Logo" className="navbar-brand" /></Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll">
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarScroll">
                  <ul className="navbar-nav mb-2 mb-lg-0" style={{ '--bs-scroll-height': '100px' }}>
                    <li className="nav-item"><a className="nav-link active" href="#">Our Methodology</a></li>
                    <li className="nav-item"><Link to="/online-test" className="nav-link">Online Tests</Link></li>
                    <li className="nav-item"><a className="nav-link" href="#">Resources</a></li>
                    <li className="nav-item"><Link to="/teams" className="nav-link">Team</Link></li>
                    <li className="nav-item"><a className="nav-link" href="#">Contact Us</a></li>
                  </ul>
                </div>
              </div>
            </nav>
          </section>
        </header>

        <section className="bg-light p-3 p-md-4 p-xl-5 position-relative bg-box">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-xxl-11">
                <div className="card border-light-subtle shadow-sm">
                  <div className="row g-0">
                    <div className="col-12 col-md-6">
                      <img className="img-fluid rounded-start w-100 h-100 object-fit-cover" loading="lazy" src={forgot} alt="Forgot Password" />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center justify-content-center">
                      <div className="col-12 col-lg-11 col-xl-10">
                        <div className="card-body p-3 p-md-4 p-xl-5">
                          <div className="row">
                            <div className="col-12">
                              <div className="mb-5">
                                <div className="text-center mb-4">
                                  <Link to="/"><img src={logo} alt="Logo" width="175" height="57" /></Link>
                                </div>
                                <h2 className="h4 text-center">Password Reset</h2>
                                <h3 className="fs-6 fw-normal text-secondary text-center m-0">
                                  {isEmailSubmitted ? 'Enter the OTP and your new password below.' : 'Provide the email address associated with your account to recover your password.'}
                                </h3>
                              </div>
                            </div>
                          </div>

                          {isEmailSubmitted ? (
                            <form onSubmit={handlePasswordSubmit}>
                              <div className="row gy-3 overflow-hidden">
                                <div className="col-12">
                                  <div className="form-floating mb-3">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="otp"
                                      id="otp"
                                      placeholder="OTP"
                                      value={otp}
                                      onChange={(e) => setOtp(e.target.value)}
                                      required
                                    />
                                    <label htmlFor="otp" className="form-label">OTP</label>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <div className="form-floating mb-3">
                                    <input
                                      type="password"
                                      className="form-control"
                                      name="newPassword"
                                      id="newPassword"
                                      placeholder="New Password"
                                      value={newPassword}
                                      onChange={(e) => setNewPassword(e.target.value)}
                                      required
                                    />
                                    <label htmlFor="newPassword" className="form-label">New Password</label>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <div className="d-grid">
                                    <button className="btn btn-dark btn-lg" type="submit">Reset Password</button>
                                  </div>
                                </div>
                              </div>
                            </form>
                          ) : (
                            <form onSubmit={handleEmailSubmit}>
                              <div className="row gy-3 overflow-hidden">
                                <div className="col-12">
                                  <div className="form-floating mb-3">
                                    <input
                                      type="email"
                                      className="form-control"
                                      name="email"
                                      id="email"
                                      placeholder="name@example.com"
                                      value={email}
                                      onChange={(e) => setEmail(e.target.value)}
                                      required
                                    />
                                    <label htmlFor="email" className="form-label">Email</label>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <div className="d-grid">
                                    <button className="btn btn-dark btn-lg" type="submit">Submit</button>
                                  </div>
                                </div>
                              </div>
                            </form>
                          )}

                          <div className="row">
                            <div className="col-12">
                              <div className="d-flex gap-2 gap-md-4 flex-column flex-md-row justify-content-md-center mt-5">
                                <Link to="/login" className="link-secondary text-decoration-none">Login</Link>
                                <Link to="/signup" className="link-secondary text-decoration-none">Register</Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="backgrond-img-box position-absolute">
            <img src={backgroundImg} alt="Background" />
          </div>
        </section>

        <footer className="main-footer">
          <div className="container">
            <div className="footer-logo">
              <Link to="/"><img src={logo} alt="Logo" /></Link>
            </div>
            <div className="footer-menu">
              <ul>
                <li><a href="#">About Us</a></li>
                <li><a href="#">Terms & Conditions</a></li>
                <li><a href="#">Privacy Policy</a></li>
                <li><a href="#">Business Association</a></li>
                <li><a href="#">Scholarships</a></li>
              </ul>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}

export default ForgotPassword;
