import React from 'react';
import Footer from './components/Footer';
import Header from './components/Header';

function Privacy() {
    return (
        <>
            <div className='app-bg'>
                <Header route='methodology' />
                <div className="about-container">
                    <h1>About Us</h1>
                    <section>
                    <h2>Welcome to EduPrepMe!</h2>
                    </section>
                    
                    <section>
                        <p>
                            At EduPrepMe, our mission is to make SAT preparation accessible and effective for all students, especially those from economically disadvantaged backgrounds. We believe that every student deserves the opportunity to succeed, regardless of their financial situation.
                        </p>
                    </section>

                    <section>
                        <h2>Our Story</h2>
                        <p>
                            The idea for EduPrepMe was sparked by a conversation about the impact of the digitized SAT format and the challenges faced by students who cannot afford expensive SAT tutoring. We set out to create a platform that offers high-quality, accessible preparation tools for everyone.
                        </p>
                        <ul>
                            <li>
                                <span>Dynamic Question Generation:</span> Using a sophisticated algorithm, our system generates questions that adapt in real-time based on the student’s performance. This ensures that the difficulty and subject matter of the questions are always perfectly aligned with the student's current ability and learning needs.
                            </li>
                            <li>
                                <span>Data-Driven Insights:</span> Our AI model continuously analyzes data from thousands of test sessions to identify patterns and trends. This analysis helps in refining our question bank and optimizing our adaptive algorithms to better serve the needs of all students.
                            </li>
                            <li>
                                <span>Personalized Feedback and Recommendations:</span> After each practice session, students receive detailed feedback highlighting their strengths and areas for improvement. The system also recommends specific topics for further study, ensuring efficient and targeted preparation.
                            </li>
                            <li>
                                <span>Continuous Learning and Updating:</span> Our AI models are regularly updated with new data, learning and adapting from the vast array of student interactions. This continuous learning process ensures that our preparation materials are always up-to-date with the latest SAT format and standards.
                            </li>
                        </ul>
                    </section>

                    <section>
                        <h2>Our Approach</h2>
                        <ul>
                            <li>
                                <span>Innovative Technology:</span> We leverage cutting-edge technology to design and develop solutions that are both innovative and effective. Our platform is built to provide a seamless user experience, combining intuitive front-end design with robust back-end functionality.
                            </li>
                            <li>
                                <span>User Experience:</span> Our design philosophy centers on creating user-friendly and visually appealing interfaces. We focus on ensuring that our platform is easy to navigate and enjoyable to use, so students can focus on their preparation without any hassle.
                            </li>
                            <li>
                                <span>High-Quality Content:</span> We are committed to producing engaging and effective educational materials. Our content is designed to support students in improving their SAT scores through comprehensive and well-structured resources.
                            </li>
                        </ul>
                    </section>

                    <section>
                        <h2>Our Commitment</h2>
                        <p>
                            We are dedicated to supporting students throughout their SAT preparation journey. By combining innovative technology with high-quality content, we aim to help every student reach their full potential.
                        </p>
                        <p>
                            Thank you for choosing EduPrepMe. We’re excited to be part of your SAT prep journey!
                        </p>
                    </section>
                </div>

                <Footer />
            </div>
        </>
    );
}

export default Privacy;
