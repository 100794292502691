import React from 'react';
import Footer from './components/Footer';
import Header from './components/Header';

import logo from './assets/methodology1.png';

function OurMethodology() {
  return (
    <>
      <div className='app-bg'>
        <Header route='methodology' />
        <div className="methodology-container">
          <section>
            <h2>Our Methodology</h2>
            <p>
              At EduPrepMe, we recognize the critical role that the SAT continues to play in the holistic college admissions process. Test scores, when considered alongside the context of a student's educational and personal background, provide valuable insights into a student's academic potential. They can affirm the hard work reflected in their grades or reveal strengths that go beyond the classroom performance.
            </p>
            <p>
              Historically, students have prepared for and taken the SAT in a paper and pen format. However, the transition to a digital format, launching in 2024 for U.S. students, represents a significant shift. The new digital SAT offers several key improvements:
            </p>
            <ul>
              <li>
                <span>Reduced Duration:</span> The digital test is more than an hour shorter, lasting just over two hours.
              </li>
              <li>
                <span>Streamlined Content:</span> Reading passages in the Reading and Writing section are shorter, each accompanied by a single question, making the test more concise and less daunting.
              </li>
            </ul>
            <img src={logo} alt="Logo" />
            <p>
              Despite these improvements, the shift to digital presents unique challenges, especially for underprivileged students who may not have access to costly preparatory courses. This is where EduPrepMe steps in. Our mission is to democratize SAT test preparation, making it accessible and equitable for all students, regardless of their financial background.
            </p>
          </section>

          <section>
            <h2>Adaptive, AI-Driven Test Preparation</h2>
            <p>
              Our AI technology powers an advanced adaptive learning system that personalizes each student's preparation journey. Here’s how it works:
            </p>
            <ul>
              <li>
                <span>Dynamic Question Generation:</span> Using a sophisticated algorithm, our system generates questions that adapt in real-time based on the student’s performance. This ensures that the difficulty and subject matter of the questions are always perfectly aligned with the student's current ability and learning needs.
              </li>
              <li>
                <span>Data-Driven Insights:</span> Our AI model continuously analyzes data from thousands of test sessions to identify patterns and trends. This analysis helps in refining our question bank and optimizing our adaptive algorithms to better serve the needs of all students.
              </li>
              <li>
                <span>Personalized Feedback and Recommendations:</span> After each practice session, students receive detailed feedback highlighting their strengths and areas for improvement. The system also recommends specific topics for further study, ensuring efficient and targeted preparation.
              </li>
              <li>
                <span>Continuous Learning and Updating:</span> Our AI models are regularly updated with new data, learning and adapting from the vast array of student interactions. This continuous learning process ensures that our preparation materials are always up-to-date with the latest SAT format and standards.
              </li>
            </ul>
          </section>


          <section>
            <p>
              By integrating these elements into our methodology, EduPrepMe ensures that every student has the tools and resources they need to succeed on the digital SAT, bridging the gap between traditional resources and the demands of a digital testing environment. Join us in making SAT preparation accessible, personal, and truly effective.
            </p>
          </section>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default OurMethodology;
