import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../assets/logo.png';
import '../App.css';
import { Link, useNavigate } from 'react-router-dom';

const Header = ({ route='' }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [activeLink, setActiveLink] = useState(route);
  const navigate = useNavigate();

  useEffect(() => {
    // Check for token and username in local storage
    const token = localStorage.getItem('student-token');
    const storedUsername = JSON.parse(token);

    if (token) {
      setIsLoggedIn(true);
      setUsername(storedUsername?.name);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('student-token');
    setIsLoggedIn(false);
    setUsername('');
    
    navigate('/');
    window.location.reload();

  
  };

  const scrollToSection = (sectionId) => {

    if(window.location.pathname !== '/'){
      navigate('/')
    }
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <header className="main-header">
      {/* Top Bar Section */}
      <section className="topBar">
        <div className="container topBar-container">
          <ul className="social-media-box">
            {/* <li>
              <a href="#">
                <i class="icon-instagram"><span class="path1"></span><span class="path2"></span><span
                    class="path3"></span></i>
              </a>
            </li>
            <li>
              <a href="#">
                <span class="icon-linkedin"></span>
              </a>
            </li>
            <li>
              <a href="#"><i class="icon-facebook"><span class="path1"></span><span class="path2"></span><span
                    class="path3"></span><span class="path4"></span><span class="path5"></span><span
                    class="path6"></span></i></a>
            </li>
            <li>
              <a href="#">
                <i class="icon-youtube"><span class="path1"></span><span class="path2"></span><span
                    class="path3"></span><span class="path4"></span><span class="path5"></span><span
                    class="path6"></span><span class="path7"></span></i>
              </a>
            </li> */}
          </ul>
          <div className="user-panel-box">
            {isLoggedIn ? (
              <>
                <span className="username">Welcome, {username}</span>
                <button onClick={handleLogout} className="btn btn-danger">
                  LOGOUT
                </button>
              </>
            ) : (
              <>
                <Link to="/login" className="btn btn-danger">
                  SIGN IN
                </Link>
                <Link to="/signup" className="btn btn-danger">
                  JOIN NOW
                </Link>
              </>
            )}
          </div>
        </div>
      </section>

      {/* Main Menu Section */}
      <section className="main-menu">
        <nav className="navbar navbar-expand-lg bg-body-tertiary">
          <div className="container">
            <Link to="/" className="navbar-brand">
              <img src={logo} alt="Logo" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarScroll"
              aria-controls="navbarScroll"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarScroll">
              <ul className="navbar-nav mb-2 mb-lg-0" style={{ '--bs-scroll-height': '100px' }}>
                <li className="nav-item">
                  <Link to="/" className={`nav-link ${activeLink === 'home' ? 'active' : ''}`} aria-current="page">
                    Home
                  </Link>
                </li>
                {isLoggedIn && (
                  <li className="nav-item">
                    <Link to="/student/dashboard" className={`nav-link ${activeLink === 'student-dashboard' ? 'active' : ''}`} onClick={() => { setActiveLink('dashboard') }}>
                       Dashboard
                    </Link>
                  </li>
                )}
                  <li className="nav-item">
                    <Link to="/methodology" className={`nav-link ${activeLink === 'methodology' ? 'active' : ''}`}>
                      Our Methodology
                    </Link>
                  </li>
                {/* {isLoggedIn && (
                  <li className="nav-item">
                    <Link to="/online-test" className={`nav-link ${activeLink === 'online-test' ? 'active' : ''}`}>
                      Online Tests
                    </Link>
                  </li>
                )} */}
              
                <li className="nav-item">
                  <Link to="/faq" className={`nav-link ${activeLink === 'faq' ? 'active' : ''}`}>
                    FAQs
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/teams" className={`nav-link ${activeLink === 'teams' ? 'active' : ''}`}>
                    Team
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link to="/news" className={`nav-link ${activeLink === 'news' ? 'active' : ''}`}>
                    News
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/blogs" className={`nav-link ${activeLink === 'blogs' ? 'active' : ''}`}>
                    Blogs
                  </Link>
                </li> */}
                <li className="nav-item">
                  <a
                    className={`nav-link ${activeLink === 'ourmethodology' ? 'active' : ''}`}
                    onClick={() => {
                      scrollToSection('contectForm')
                      setActiveLink('ourmethodology')
                    }}
                    href="#contectForm"
                  >
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </section>
    </header>
  );
};

export default Header;
