import * as Yup from "yup";
import common_passwords from './common-passwords'

const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const mobilePattern = /^\d{10}$/;
const alphaNumericPattern = /^[ A-Za-z0-9_@./#&+-]*$/

export const SignUpSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, "Name should be at leaset 2 characters long.")
        .max(50, "Name should not be more than 50 characters long.")
        .test('name', "Name can not have white spaces.", (value) => {
            return value?.trim() === value
        })
        .required("Name is required"),

    email: Yup.string()
    .email("Please provide valid email.")
    .test('email', "Email can not have white spaces.", (value) => {
        return value?.trim() === value
    })
    .required("Email is required"),

    // mobile: Yup.string()
    //     .required("Phone number is required")
    //     .matches(
    //         /^([0]{1}|\+?[234]{3})([7-9]{1})([0|1]{1})([\d]{1})([\d]{7})$/g,
    //         "Invalid phone number"
    //     ),

    password: Yup.string()
        .min(6, "Name should be at leaset 6 characters long.")
        .max(50, "Name should not be more than 50 characters long.")
        .test('password', "Password must contain alphanumeric characters.", (value) => {
            return alphaNumericPattern.test(value)
        })
        .test('password', "Please choose a stronger password.", (value) => {
            return !common_passwords.includes(value?.toLocaleLowerCase())
        })
        .required("Password is required"),

    gender: Yup.string(),

    country: Yup.object(),
        // .test('country', "Country is required", (value) => {
        //     return !!value?.label
        // }),
    state: Yup.object(),
    // .test('state', "State is required", (value) => {
    //     return !!value?.label
    // }),
    city: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .test('city', "City can not have white spaces.", (value) => {
            return value?.trim() === value
        }),
        // .required("City is required"),
});

export const SignInSchema = Yup.object().shape({
    email: Yup.string()
    .email("Please provide valid email.")
    .test('email', "Email can not have white spaces.", (value) => {
        return value?.trim() === value
    })
    .required("Email is required"),
    password: Yup.string()
        .required("Password is required"),
});