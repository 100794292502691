// src/context/TestContext.js
import React, { createContext, useState } from 'react';

export const TestContext = createContext();

export const TestProvider = ({ children }) => {
  const [testData, setTestData] = useState(null);

  return (
    <TestContext.Provider value={{ testData, setTestData }}>
      {children}
    </TestContext.Provider>
  );
};
