import moment from 'moment'

export const secondsToMs = (d) => {
    d = Number(d);

    var m = Math.floor(d / 60);
    var s = Math.floor(d % 60);

    var mDisplay = m > 0 ? m + (m == 1 ? " min " : " min ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " sec" : " sec ") : "";
    return mDisplay + sDisplay; 
}

export const secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " min " : " mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " sec" : " sec ") : "";
    return hDisplay + mDisplay + sDisplay; 
}

export const convertIndexToOption = (index) => {
    if (index === 0) {
        return 'Option A'
    } else if (index === 1) {
        return 'Option B'
    } else if (index === 2) {
        return 'Option C'
    } else if (index === 3) {
        return 'Option D'
    } else {
        return 'N/A'
    }
}

export const createDynamicCounts = (baseValue, frequencyInSecs, count) => {
    const baseMoment = moment('2024-08-08T00:00:01')
    const currentMoment = moment()
    const diff = currentMoment.diff(baseMoment, 'seconds')
    return baseValue + (Math.floor(diff / frequencyInSecs)*count)
} 