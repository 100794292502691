import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'
import faqImg from './assets/eaq.jpeg'
import Footer from './components/Footer';
import Header from './components/Header';
import { getDashboard } from './components/apiCalls';

function Faq() {
  const [faqList, setFaqList] = useState([]);

  useEffect(() => {
    getFaqList();
  }, []);

  const getFaqList = async () => {
    try {
      let data = await getDashboard();
      if (data && data.faqList) {
        setFaqList(data.faqList);
      } else {
        console.error("API response format is incorrect or missing 'faqList'");
      }
    } catch (error) {
      console.error("An error occurred while fetching FAQ data:", error);
    }
  };

  return (
    <>
      <div>
        <Header route='faq' />

        <section className="bg-light py-3 py-md-5">
          <div className="container">
            <div className="row gy-5 gy-lg-0 align-items-lg-center">
              <div className="col-12 col-lg-6">
                <img className="img-fluid rounded" loading="lazy" src={faqImg} alt="How can we help you?" />
              </div>
              <div className="col-12 col-lg-6">
                <div className="row justify-content-xl-end">
                  <div className="col-12 col-xl-11">
                    <h2 className="h1 mb-3">How can we help you?</h2>
                    <p className="lead fs-4 text-secondary mb-5">We hope you have found an answer to your question. If you need any help, please search your query on our Support Center or contact us via email.</p>
                    <div className="accordion accordion-flush" id="accordionExample">
                      {faqList.map((faq) => (
                        <div className="accordion-item" key={faq.id}>
                          <h2 className="accordion-header" id={`heading-${faq.id}`}>
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse-${faq.id}`} aria-expanded="false" aria-controls={`collapse-${faq.id}`}>
                              {faq.question}
                            </button>
                          </h2>
                          <div id={`collapse-${faq.id}`} className="accordion-collapse collapse" aria-labelledby={`heading-${faq.id}`} data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                              <p>{faq.answer}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </>
  );
}

export default Faq;
